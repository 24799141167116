<template>
  <div>
    <v-simple-table fixed-header height="calc(100vh - 145px)" class="table-padding-2">
      <template v-slot:default>
        <thead class="v-data-table-header">
        <tr>
          <th role="columnheader" class="text-center">
            <SelectPos :label="$t('labels.pos')" :placeholder="$t('labels.pos')" name="id_pos" sort-name="pos_code" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" class="text-center">
            <SelectFilter :options="statusOptions" :label="$t('labels.status')" :placeholder="$t('labels.status')" name="status" sort-name="goods_receipt_status" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" class="text-center">
            <InputFilter :label="$t('labels.session_code')" :placeholder="$t('labels.session_code')" name="goods_receipt_tracking" sort-name="goods_receipt_tracking" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" class="text-center">
            <InputFilter :label="$t('labels.tracking')" :placeholder="$t('labels.tracking')" name="tracking_id" sort-name="tracking_id" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" class="text-center">
            <DateRangeFilter :label="$t('labels.create_time')" :placeholder="$t('labels.create_time')" name="create_time" sort-name="create_time" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
          </th>
          <th role="columnheader" class="text-center">
            <DateRangeFilter :label="$t('labels.receipt_time')" :placeholder="$t('labels.receipt_time')" name="handle_time" sort-name="handle_time" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
          </th>
          <th role="columnheader" class="text-center">
            <InputFilter :label="$t('labels.employee_create')" :placeholder="$t('labels.employee_create')" name="identity_create_name" sort-name="identity_create_name" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th role="columnheader" class="text-center">
            <!-- <InputFilter :label="$t('labels.employee_receipt')" :placeholder="$t('labels.employee_receipt')" name="id_employee_handle" sort-name="employee_handle_name" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" /> -->
            <SelectEmployee :label="$t('labels.employee_receipt')" :placeholder="$t('labels.employee_receipt')" name="id_employee_handle" sort-name="employee_handle_name" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
          </th>
          <th>
            <SelectFilter :options="imageOptions" :label="$t('labels.image')" :placeholder="$t('labels.image')" name="image" sort-name="url_images" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th>
            <InputFilter :label="$t('labels.sku')" :placeholder="$t('labels.sku')" name="sku" sort-name="sku" :default-value="filters.sku" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th>
            <InputFilter :label="$t('labels.barcode')" :placeholder="$t('labels.barcode')" name="customer_goods_barcode" sort-name="customer_goods_barcode" :default-value="filters.customer_goods_barcode" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th>
            <InputFilterFromTo :label="$t('labels.request_quantity')" :placeholder="$t('labels.request_quantity')" name="request_quantity" sort-name="request_quantity" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          <th>
            <InputFilterFromTo :label="$t('labels.import')" :placeholder="$t('labels.import')" name="quantity" sort-name="quantity" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in items" :key="item.id" class="text-center">
          <td>{{ item.pos_code }}</td>
          <td>{{ item.status_name }}</td>
          <td>{{ item.goods_receipt_tracking }}</td>
          <td>
            <span v-if="checkPermission(['pos_goods_receipt_update'])" class="cursor-pointer blue--text text-decoration-underline" @click="showUpdateDialog(item)">
              {{ item.tracking_id }}
            </span>
            <span v-else>{{ item.tracking_id }}</span>
          </td>
          <td>{{ formatDateTime(item.create_time) }}</td>
          <td>{{ item.handle_time ? formatDateTime(item.handle_time) : '' }}</td>
          <td>{{ item.identity_create_name }}</td>
          <td>{{ item.employee_handle_name }}</td>
          <td><ImageViewer v-if="item.url_images" :url="item.url_images"/></td>
          <td>{{ item.sku }}</td>
          <td>{{ item.customer_goods_barcode }}</td>
          <td>{{ item.request_quantity }}</td>
          <td>
            <span>{{ item.quantity }}</span>
            <v-icon v-if="checkPermission(['super_admin']) && !item.no_label && !item.uid_printed_on_item && !item.has_stamp && item.quantity > 0"
              small color="primary" @click="downloadStamp(item)">mdi-download</v-icon>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="pt-3">
      <v-col cols="12" md="4">
        <div class="d-flex table-footer-count">
          <div class="mr-2">{{ $t('labels.tracking') }}: <b>{{ formatNumber(sum.sumTracking) }}</b></div>
          <div class="mr-2">{{ $t('labels.sku') }}: <b>{{ formatNumber(sum.sumGoods) }}</b></div>
        </div>
        <div class="d-flex table-footer-count mt-1">
          <!--<div class="mr-2">Y.cầu: <b>{{ formatNumber(sum.sumRequestQuantity) }}</b></div>-->
          <div class="mr-2">{{ $t('labels.import') }}: <b>{{ formatNumber(sum.sumQuantity) }}</b></div>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="text-right">
        <v-pagination
            v-model="page"
            :length="totalPage"
            :total-visible="6"
        ></v-pagination>
      </v-col>
      <v-col cols="12" md="4">
        <div class="d-flex justify-end">
          <!-- <v-btn color="success" @click="exportExcel" v-if="checkPermission(['goods_receipt_excel'])" style="height: 40px" class="mr-2"><v-icon>mdi-download</v-icon> {{ $t('labels.excel') }}</v-btn> -->
          <div style="width: 100px;">
            <v-text-field class="c-input-xs mr-2" dense outlined hide-details v-model.number="page" :label="$t('labels.page')" :placeholder="$t('labels.page')"></v-text-field>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="quantityDialog" persistent max-width="400px">
      <ReceiptUpdateQuantity v-if="quantityDialog" :item="updatingItem" @cancel="hideUpdateDialog" @refreshData="getList"/>
    </v-dialog>
  </div>
</template>

<script>
import {httpClient} from "@/libs/http";
import {IMAGE_OPTIONS} from "@/libs/const";
import { debounce } from "lodash/function";

export default {
  components: {
    SelectPos: () => import('@/components/table/SelectPos'),
    SelectFilter: () => import('@/components/table/SelectFilter'),
    InputFilter: () => import('@/components/table/InputFilter'),
    InputFilterFromTo: () => import('@/components/table/InputFilterFromTo'),
    DateRangeFilter: () => import('@/components/table/DateRangeFilter'),
    SelectEmployee: () => import('@/components/table/SelectEmployee'),
    ImageViewer: () => import('@/components/common/ImageViewer'),
    ReceiptUpdateQuantity: () => import('@/components/pos_goods/ReceiptUpdateQuantity'),
  },
  name: "ReceiptHistory",
  data: () => ({
    page: 1,
    totalPage: 1,
    items: [],
    updatingItem: {},
    filters: {},
    sort: {},
    sum: {},
    viewOption: 'view0',
    statusOptions: [],
    imageOptions: [...IMAGE_OPTIONS],
    quantityDialog: false,
    isLoading: false
  }),
  watch: {
    page() {
      this.getList()
    },
    filters: {
      handler() {
        this.page = 1
        this.getList()
      },
      deep: true
    }
  },
  created() {
    const statusOptions = [
      {
        text: this.$t('labels.receipt_status_1'),
        value: 1
      },
      {
        text: this.$t('labels.receipt_status_5'),
        value: 5
      },
    ]
    this.statusOptions = [...statusOptions]
  },
  mounted() {
    const sku = this.getUrlParameter('sku')
    const customer_goods_barcode = this.getUrlParameter('barcode')
    if (sku) {
      this.filters = {...this.filters, sku}
    }
    if (customer_goods_barcode) {
      this.filters = {...this.filters, customer_goods_barcode}
    }

    this.getList()
  },
  methods: {
    getList: debounce(function() {
      httpClient.post('/goods-receipt-history-list', {...this.filters, page: this.page}).then(({data}) => {
        this.totalPage = data.totalPage
        this.items = [...data.rows]
        this.sum = {...data.sum}
      })
    }, 1000),
    setViewOption(mode) {
      this.viewOption = mode
    },
    onFilterChange(filter) {
      this.filters = {...this.filters, [filter.name]: filter.value}
    },
    onSortChange(sort) {
      this.filters = {...this.filters, ...sort}
    },
    getCellByItem(data) {
      if (data.items && data.items.length > 0) {
        const items = JSON.parse(data.items);
        const item = items.find(i => i.id_goods_receipt_history == data.id)
        const goods_receipt_basket = item && item.goods_receipt_basket || []
        const locations = goods_receipt_basket.map(i => (i.location)) || []
        return '<div style="text-transform: uppercase">' + locations.join(',').split(',').join('<br>') + '</div>'
      } else {
        return data.warehouse_location || null;
      }
    },
    async exportExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.waiting_file_download'))
        return false
      }
      this.isLoading = true
      const filename = 'nhap-kho.xlsx'

      try {
        await this.downloadExcelFile('/goods-receipt-history-export', {...this.filters, viewMode: this.viewOption}, filename)
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
      }
    },
    async downloadStamp(item) {
      const stampSize = item.id_conformity_profile ? '50x40' : '50x20'
      const stampName = `${stampSize}_${item.sku}.pdf`
      this.downloadExcelFile(`${process.env.VUE_APP_API_V2_URL}/generate-goods-stamp`, {
        id_goods: item.id_goods,
        id_goods_receipt_history: item.id,
      }, stampName)
    },
    showUpdateDialog(item) {
      this.quantityDialog = true
      this.updatingItem = {...item}
    },
    hideUpdateDialog() {
      this.quantityDialog = false
      this.updatingItem = {}
    }
  },
}
</script>

<style scoped>

</style>
